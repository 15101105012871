#app {
  text-align: center;
}

.logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: logo-spin infinite 20s linear;
  }
}

header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

a {
  color: #61dafb;
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#content {
  border: 2px solid grey;
  width: 80%;
  margin: auto;
  height: 100%;
  margin-top: auto;
  padding: 1em;
  margin-top: 0.5em;
  border-radius: 0.25em;
}